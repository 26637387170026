// CTA button click event - 'button_clicks'
$(".button, button, input[type='submit'], input[type='button']").on("click", function() {
  // e.preventDefault();
  const buttonText = $(this).text().trim() || $(this).attr("value");

  let buttonLocation = "Body";
  if ($(this).parents(".site-header").length) {
    buttonLocation = "Header";
  } else if ($(this).parents(".site-footer").length || $(this).parents(".newsletter-cta").length) {
    buttonLocation = "Footer";
  } else if ($(this).parents(".bnef-calc-cta").length) {
    buttonLocation = "2-column-block-see-it-for-yourself";
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
  "event" : "ctaButton",  // CONSTANT
  "button_location" : buttonLocation,  // E.g. Header / Body / Footer
  "button_text" : buttonText,  // E.g. Donate / Get in touch / Sign-up
  });

  // console.log(window.dataLayer);
});

// Menu navigation interaction - 'menu-navigation'
$(".site-header a, .site-footer a").on("click", function() {
  // e.preventDefault();
  let menuType = "";
  let menuHeader = "";
  let menuLayout = "Mobile";
  let menuItemSelected = $(this).text().trim() || "";

  if($(this).hasClass("site-header-logo")) {
    menuItemSelected = "logo";
  }

  // footer menu menu_type
  if ($(this).parents(".site-footer").length) {
    menuType = "Footer";
  }
  // header top menu menu_type
  else if ($(this).parents(".site-header__top").length) {
    if ($(this).children(".site-logo").length) {
      menuType = "CAP Logo";
    } else {
      menuType = "Top";
    }
  }
  // header main menu menu_type and menu_header
  else {
    menuType = "Main";
    menuHeader = $(this).parents(".main-nav-list__level-1").siblings(".menu-tab").text().trim();
  }

  // menu-layout
  if (window.innerWidth >= 960 ) {
    menuLayout = "Desktop";
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    "event" : "navigation_menu_click",  // CONSTANT
    "menu_type" : menuType,  // E.g. Top, Main, Footer, CAP Logo
    "menu_header" : menuHeader,  // E.g Connect / Get Involved
    "menu_layout" : menuLayout,  // Mobile or Desktop
    "menu_item_selected": menuItemSelected  // Navigation item clicked, e.g. "Money and debt advice"
  });

  // console.log(window.dataLayer);
});

// For deduping an array of strings, like for errors
function unique(list) {
  var result = [];
  $.each(list, function(i, e) {
    if ($.inArray(e, result) == -1) result.push(e);
  });
  return result;
}

// Validation form Form Assembly submissions
function validateFormAssembly(form, isPagination = false) {
  let formId = isPagination ? form.parent().attr("id") : form.attr("id");
  let formName = form.siblings(".wFormTitle").text().trim() || $(".site-hero h1.title").text() || formId;

  let formErrors = [];
  let requiredFields = [];

  if (isPagination) {
    requiredFields = form.find("[aria-required='true']:not([type='hidden'])");
  } else {
    requiredFields = $("[aria-required='true']:not([type='hidden'])");
  }

  const MailingStreet = $('[title="Mailing Street"]');
  if (MailingStreet.length === 2) {
    MailingStreet[0].value = MailingStreet[1].value;
  }

  requiredFields.each(function() {
    const $this = $(this);
    const inputType = $this.attr("type") || $this.prop("nodeName").toLowerCase();
    let fieldTitle = $this.parents(".oneField").children("label").text();

    if(["text", "textarea", "select"].includes(inputType)) {
      if (!$this.val()) {
        formErrors.push(fieldTitle);
      } else if ($this.hasClass("validate-email")) {
        var emailValidationRegex = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if(!emailValidationRegex.test($this.val())) {
          formErrors.push(fieldTitle);
        }
      }
    }

    if(inputType == "radio") {
      radioGroupName = $this.attr("name");
      if(!$("[name='" + radioGroupName + "']:checked").val()) {
        formErrors.push(fieldTitle);
      }
    }
  });

  formErrors = unique(formErrors);

  if (formErrors.length) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": "form_invalid",
        "form_id": formId,
        "form_name": formName,
        "form_location": "Body",
        "form_errors": formErrors
    });

    // console.log(window.dataLayer);
    return false;
  } else {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": "form_valid",
        "form_id": formId,
        "form_name": formName,
        "form_location": "Body",
    });

    // console.log(window.dataLayer);
    return true;
  }
}

// Form Assembly forms
// Includes: Gift Aid update submission valid data ('gift_aid_update')
// Includes: Increasing monthly donation amount ('ga4_begin_checkout')
$(".form-assembly form").submit(function(e) {
  // e.preventDefault();
  const formValid = validateFormAssembly($(this));

  if(formValid) {
    let formId = $(this).attr("id");
    let formName = $(this).siblings(".wFormTitle").text().trim();

    // FormAssembly form in Gift Aid update page (/get-involved/donate/manage-my-gift-aid)
    if (window.location.pathname == "/get-involved/donate/manage-my-gift-aid") {
      // console.log("GiftAid form submitted");

      let giftAidOption = $(this).find("#tfa_237").find("input[type=radio]:checked + label").text().trim();
      let marketingPreferences = "";
      // email preference
      if ($(this).find("#tfa_182").is(":checked")) {
        marketingPreferences = marketingPreferences + "Email + " ;
      }
      // letter preference
      if ($(this).find("#tfa_194").is(":checked")) {
        marketingPreferences = marketingPreferences + "Letter + " ;
      }
      // phone preference
      if ($(this).find("#tfa_205").is(":checked")) {
        marketingPreferences = marketingPreferences + "Phone + " ;
      }
      // SMS preference
      if ($(this).find("#tfa_216").is(":checked")) {
        marketingPreferences = marketingPreferences + "SMS + " ;
      }
      // total preferences
      if (marketingPreferences.length) {
        marketingPreferences = marketingPreferences.substring(0, marketingPreferences.length - 3); // remove " + " at the end
      } else {
        marketingPreferences = "None";
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": "gift_aid_management",  // CONSTANT
        "gift_aid_option" : giftAidOption,  // Yes + last four years / Yes from now on / No
        "marketing_preferences": marketingPreferences  // Marketing opt-in e.g. Email + Letter + Phone + SMS or None
      });

      // Form valid
      window.dataLayer.push({
        "event": "form_valid",
        "form_id": formId,
        "form_name": formName,
        "form_location": "Body",
      });

      // console.log(window.dataLayer);
    }

    // Change monthly donation amount request
    else if (window.location.pathname == "/get-involved/donate/manage-my-monthly-donation") {
      let price = $(this).find("#tfa_242").val();

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'ecommerce': null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        'event' : 'begin_checkout',                   // CONSTANT
        'ecommerce': {
          'items': [
            {
              'item_id': 012,                         // 001 / 011 / 012
              'item_name': 'life-changer-increase',   // One-off donation / life-changer-new / life-changer-increase
              'item_category': 'Donations',           // Category e.g Donations
              'currency': 'GBP',                      // CONSTANT
              'price': price,                         // E.g. 50 No pound symbols. No commas if greater than 1000
              'quantity': 1                           // CONSTANT
            }
          ]
        }
      });

      // Form valid
      window.dataLayer.push({
        "event": "form_valid",
        "form_id": formId,
        "form_name": formName,
        "form_location": "Body",
      });

      // console.log(window.dataLayer);
    }
  }
});

// Form Assembly pagination
$("body").on("click", ".wfPageNextButton", function() {
  const currentPage = $(this).parent(".wfPagingButtons").parent();
  const pageValid = validateFormAssembly(currentPage.children(".pageSection"), true);

  if (pageValid) {
    const nextPage = currentPage.next();
    if (nextPage.length && nextPage.attr("id").indexOf("wfPgIndex") >= 0) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": "virtual_page_view", // CONSTANT
          "virtual_page_url": window.location.pathname, // Virtual page view URL e.g. /donate/monthly/your-address/?amount=24
          "virtual_page_path": window.location.pathname, // Virtual page path e.g. /donate/monthly/your-address/
          "virtual_page_title": nextPage.attr("id") // Virtual page title e.g. Funnel Step 2: Your Address. Ensure all steps have a title.
      });

      // console.log(window.dataLayer);
    }
  }
});

// Starting a donation funnel - 'ga4_begin_checkout'
$(".donate-widget-form").submit(function() {
  // Delete cookie that is checked on donation success/fail screen
  // To avoid THAT datalayer event being triggered on just a page reload
  document.cookie = "CAP_donation_confirmation_shown=false;path=/;";

  const frequency = $(this).data("frequency");

  let item_id = "";
  let item_name = "";
  let price = $(this).find("input.donate-amount").val();

  if (frequency == "single") {
    item_id = "001";
    item_name = "one-off donation";
  }

  if (frequency == "monthly") {
    item_id = "011";
    item_name = "life-changer-new";
  }

  const windowPathname = window.location.pathname;
  let entrance_route = "Donation Modal";

  if (windowPathname.includes("/get-involved/donate")) {
    entrance_route = "BAU Donations Page";
  }

  if (windowPathname.includes("/appeals")) {
    entrance_route = "Appeals Page";
  }


  if (item_id && item_name) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'ecommerce': null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      'event' : 'begin_checkout',             // CONSTANT
      'entrance_route': entrance_route,
      'ecommerce': {
        'items': [
          {
            'item_id': item_id,               // 001 / 011 / 012
            'item_name': item_name,           // One-off donation / life-changer-new / life-changer-increase
            'item_category': 'Donations',     // Category e.g Donations
            'currency': 'GBP',                // CONSTANT
            'price': price,                   // E.g. 50 No pound symbols. No commas if greater than 1000
            'quantity': 1,                    // CONSTANT
          }
        ]
      }
    });

    // console.log(window.dataLayer);
  }
});

// form_valid or form_invalid for the donation widget
$(".donate-widget__button").on("click", function() {
  const donateAmountInput = $(this).siblings(".donate-amount");
  const form = donateAmountInput.parents(".donate-widget-form");
  const formId = form.data("frequency");

  let formName = ""
  if (formId == "monthly") {
    formName = "Donate widget monthly";
  } else {
    formName = "Donate widget single";
  }

  if (donateAmountInput.is(":invalid")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'form_invalid',	// form_valid or form_invalid
      'form_id': "donate-widget-" + formId,
      'form_name': formName, // E.g. Newsletter Sign-Up | Personal Details | Payment details etc
      'form_location': 'Body',
      'form_errors' : ["Other amount"] // An array of form field errors.  Please pass validations errors client and server side. Do not include any empty fields.
    });
  } else {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'form_valid',	// CONSTANT
      'form_id': "donate-widget-" + formId,  // The Form ID E.g. 14
      'form_name': formName, // E.g. Newsletter Sign-Up | Personal Details | Payment details | Gift Aid
      'form_location': 'Body', // Form location on page, e.g. hero, body, footer.
    });
  }

  // console.log(window.dataLayer);
});